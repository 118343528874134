var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "数据规则/按钮权限配置",
        width: "365",
        closable: false,
        visible: _vm.visible,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-tabs",
        { attrs: { defaultActiveKey: "1" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "数据规则" } },
            [
              _vm.dataruleList.length > 0
                ? _c(
                    "a-checkbox-group",
                    {
                      model: {
                        value: _vm.dataruleChecked,
                        callback: function ($$v) {
                          _vm.dataruleChecked = $$v
                        },
                        expression: "dataruleChecked",
                      },
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _vm._l(_vm.dataruleList, function (item, index) {
                            return _c(
                              "a-col",
                              { key: "dr" + index, attrs: { span: 24 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: item.id } },
                                  [_vm._v(_vm._s(item.ruleName))]
                                ),
                              ],
                              1
                            )
                          }),
                          _c("a-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "margin-top": "15px",
                                },
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      icon: "save",
                                    },
                                    on: { click: _vm.saveDataruleForRole },
                                  },
                                  [_vm._v("点击保存")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _c("div", [_c("h3", [_vm._v("无配置信息!")])]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }